'use client';

import React, { useState } from 'react';
import styles from './floating-cta.module.scss';
import { Button, Modal, useIsMobile } from '@weski-monorepo/react-components';
import useScrollPosition from '../../../utils/use-scroll-position';
import SearchFormClient from '../../../features/search-form/search-form';
import { Portal } from '@radix-ui/react-portal';
import { useSearchFocusStore } from '../search-focus-store';

const FloatingCta = () => {
  const y = useScrollPosition();
  const isMobile = useIsMobile();
  const show = isMobile && y > 500;
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const { searchBarFocused, setSearchBarFocused } = useSearchFocusStore();
  if (!show) {
    return null;
  }
  return (
    <Portal asChild>
      <div className={styles.floatingCta}>
        <Modal
          open={showBottomSheet || searchBarFocused}
          mode="bottomSheet"
          closable
          showCloseButton={false}
          onClose={() => {
            setShowBottomSheet(false);
            setSearchBarFocused(false);
          }}
        >
          <SearchFormClient showComponentsField={false} layout="vertical" />
        </Modal>
        <Button
          type="button"
          variant="main"
          onClick={() => {
            setShowBottomSheet(true);
          }}
        >
          Find your trip
        </Button>
      </div>
    </Portal>
  );
};

export default FloatingCta;
