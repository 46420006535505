'use client';

import { WhyBookWithWeSkiSection as WhyBookWithWeSkiSectionOriginal } from '@weski-monorepo/react-components';
import { useSearchFocusStore } from './search-focus-store';
import { useOrigin } from '../../services/origins/useOrigin';
import { useDisplayWithCurrency } from '../../services/use-display-with-currency';
import { useSkiDealsEnabled } from '../../features/ski-deals/ski-deals-definitions';

const WhyBookWithWeSkiSection = () => {
  const { setSearchBarFocused } = useSearchFocusStore();
  const origin = useOrigin();
  const displayWithCurrency = useDisplayWithCurrency();
  const skiDealsEnabled = useSkiDealsEnabled();

  return (
    <WhyBookWithWeSkiSectionOriginal
      focusOnSearch={() => {
        setSearchBarFocused(true);
      }}
      fixedDepositValue={
        origin?.deposit_type_for_new_group === 'fixed'
          ? displayWithCurrency(origin?.deposit_value_for_new_group)
          : undefined
      }
      skiDealsEnabled={skiDealsEnabled}
    />
  );
};

export default WhyBookWithWeSkiSection;
